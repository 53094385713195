import { ArticleParagraph2, IconArrowUpRight16 } from '@cian/ui-kit';
import { FC } from 'react';

import { IFilterLinkProps } from './types';

import styles from './FilterLink.css';

export const FilterLink: FC<IFilterLinkProps> = props => {
  const { href, children } = props;

  return (
    <a className={styles['link']} href={href} rel="noreferrer">
      <ArticleParagraph2 color="primary_100">{children}</ArticleParagraph2>
      <IconArrowUpRight16 color="primary_100" />
    </a>
  );
};

FilterLink.displayName = 'FilterLink';
