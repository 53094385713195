/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useElementForkRef, useValueRef } from '@cian/react-utils';
import * as React from 'react';
import { FC } from 'react';

export interface IOutsideProps {
  /** Вызывается по клику на наружние элементы */
  onOutside(): void;
  /**
   * Если неактивно, то onOutside не вызывается
   *
   * @default true
   */
  active: boolean;
  /**
   * Один элемент с проброшенным ref до DOM-ноды
   */
  children: React.ReactElement;
  /**
   * Массив RefObject
   * Со ссылками на дополнительные, помимо children, элементы
   * Клики внутри которых не будут считаться внешними
   *
   * @default []
   */
  insideRefs: React.RefObject<Element | null>[];
}
/**
 * Компонента для обработки клика снаружи children
 *
 * Умеет принимать ссылки на дополнительные DOM-ноды которые не будут считаться наружними
 *
 * Не рендерит дополнительных элементов и оберток
 *
 * @export
 * @param {IOutsideProps} props
 * @returns
 */
export const Outside: FC<IOutsideProps> = (props: IOutsideProps) => {
  /**
   * Запоминаем пропсы для того чтобы не переподписываться на клик
   * Переподписка может нарушить порядок вызова обработчиков относительно React-дерева
   * Это просто нежелательно
   */
  const propsRef = useValueRef(props);
  const [children, childrenRef] = useElementForkRef<Element>(props.children);

  React.useEffect(() => {
    const handleDocumentClick = (event: MouseEvent): void => {
      const currentProps = propsRef.current;

      if (!currentProps.active) {
        return;
      }

      const isInside = [childrenRef, ...currentProps.insideRefs].some(({ current }) => {
        return current && current.contains(event.target as Node);
      });

      if (!isInside && currentProps.active) {
        currentProps.onOutside();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};
