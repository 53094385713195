/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useToggleState } from '@cian/react-utils';
import { InputAdornmentButton } from '@cian/ui-kit';
import { FC } from 'react';

import { DistrictsModal } from 'shared/components/GeoSuggest/DistrictsModal/DistrictsModal';
import { MetroModal } from 'shared/components/GeoSuggest/MetroModal/MetroModal';
import { RegionModal } from 'shared/components/GeoSuggest/RegionModal/RegionModal';
import { useApiGeoGetDistrictsTreeQuery } from 'shared/store/serverSide/monolith-cian-realty/api/geo/get-districts-tree/query';
import { useCurrentLocation } from 'shared/utils/hooks/useCurrentLocation';
import { useOffersCountTooltip } from 'shared/utils/hooks/useOffersCountTooltip';
import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';
import { useSelectedUndergroundIds } from 'shared/utils/hooks/useSelectedUndergroundIds';

import { EGeoModalType } from './types';

import styles from './styles.css';

const availableMetroScheme: number[] = require('@cian/metro/ready');

export const GeoSuggestRightAddon: FC = () => {
  const { state: isMetroModalOpened, toggle: toggleMetroModalOpened } = useToggleState();
  const { state: isDistrictsModalOpened, toggle: toggleDistrictsModalOpened } = useToggleState();
  const { state: isRegionModalOpened, toggle: toggleRegionModalOpened } = useToggleState();

  const selectedIds = useSelectedUndergroundIds();
  const currentLocation = useCurrentLocation();
  const { data: districts } = useApiGeoGetDistrictsTreeQuery();

  const { opened } = useOffersCountTooltip();
  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  const hasUnderground = availableMetroScheme.includes(currentLocation);

  const handleModalOpen = (modalType: EGeoModalType) => () => {
    if (opened) {
      setTargetOffersCountTooltip(null);
    }

    switch (modalType) {
      case EGeoModalType.Region:
        toggleRegionModalOpened();
        break;
      case EGeoModalType.Metro:
        toggleMetroModalOpened();
        break;
      case EGeoModalType.District:
        toggleDistrictsModalOpened();
        break;
    }
  };

  return (
    <div className={styles['container']}>
      <InputAdornmentButton type="button" onClick={handleModalOpen(EGeoModalType.Region)}>
        Регион
      </InputAdornmentButton>
      {hasUnderground ? (
        <InputAdornmentButton type="button" onClick={handleModalOpen(EGeoModalType.Metro)}>
          Метро
        </InputAdornmentButton>
      ) : districts?.length ? (
        <InputAdornmentButton type="button" onClick={handleModalOpen(EGeoModalType.District)}>
          Район
        </InputAdornmentButton>
      ) : null}

      <MetroModal
        open={isMetroModalOpened}
        region={currentLocation}
        selectedIds={selectedIds}
        onClose={toggleMetroModalOpened}
      />
      <DistrictsModal open={isDistrictsModalOpened} onClose={toggleDistrictsModalOpened} />
      <RegionModal open={isRegionModalOpened} onClose={toggleRegionModalOpened} />
    </div>
  );
};
