/* eslint-disable @typescript-eslint/no-explicit-any */
import { GeoValue } from 'shared/types/filters/geo';

export function normalizeGeoObject(geoObject: any): GeoValue {
  const type = geoObject.group !== 'road' ? (geoObject.group !== 'city' ? geoObject.group : 'location') : 'highway';

  return {
    id: geoObject.id,
    title: geoObject.title || geoObject.name,
    type,
  };
}
