import { IconSuccess16 } from '@cian/ui-kit';
import clsx from 'clsx';
import { FC, JSX } from 'react';

import { ISelectOptionProps } from './types';

import styles from './SelectOption.css';

export const SelectOption: FC<ISelectOptionProps> = props => {
  const { checked, label, onChange } = props;

  const renderIcon = (): JSX.Element | null => {
    if (!checked) {
      return null;
    }

    return (
      <div className={styles['icon']} data-testid="icon">
        <IconSuccess16 color="primary_100" />
      </div>
    );
  };

  const handleClick = (): void => {
    if (onChange) {
      onChange();
    }
  };

  return (
    <div className={clsx(styles['option'])} onClick={handleClick}>
      {label}
      {renderIcon()}
    </div>
  );
};

SelectOption.displayName = 'SelectOption';
