import { ILogger } from '@cian/logger/shared';
import {
  GenericError,
  HttpBadStatusCodeError,
  IErrorDetails,
  UnknownError,
  ValidationError,
} from '@cian/peperrors/shared';

import { ILogRequestService, LogRequestService } from '../../LogRequestService';
import { IFetchAssertsService } from '../IFetchAssertsService';

export class FetchAssertsService implements IFetchAssertsService {
  private readonly domain: string;
  private readonly model: object;
  private readonly logRequestService: ILogRequestService;

  public constructor(model: object, domain: string, logger: ILogger) {
    this.domain = domain;
    this.model = model;
    this.logRequestService = new LogRequestService(logger);
  }

  public assertUnknownServerError(error: unknown): UnknownError | GenericError {
    let validError: GenericError | UnknownError;

    const defaultDetails: IErrorDetails = {
      level: 'error',
      model: this.getSerializedModel(),
    };

    if (error instanceof GenericError) {
      validError = error;

      Object.assign<IErrorDetails, IErrorDetails>(validError.details as IErrorDetails, defaultDetails);
    } else if (error instanceof Error) {
      validError = new UnknownError({
        details: {
          ...defaultDetails,
          description: 'При ошибке был передан инстанс Error, распарсили его и привели к UnknownError',
          name: error.name,
        },
        domain: this.domain,
        message: error.message,
      });
    } else {
      validError = new UnknownError({
        details: {
          ...defaultDetails,
          description: 'Не была передана ошибка, передалось ни известно что, нужно разбираться',
        },
        domain: this.domain,
        message: 'Неизвестная ошибка в запросе',
      });
    }

    this.logRequestService.logFetchError(validError);

    return validError;
  }

  public assertUnknownStatusCodeError(statusCode: number): HttpBadStatusCodeError {
    const httpBadStatusCodeError = new HttpBadStatusCodeError({
      details: {
        level: 'error',
        model: this.getSerializedModel(),
      },
      domain: this.domain,
      message: 'Неизвестный статус ответа',
      statusCode,
    });

    this.logRequestService.logFetchError(httpBadStatusCodeError);

    return httpBadStatusCodeError;
  }

  public assert400StatusCodeError<Result extends { response?: { errors?: unknown[] | object; message?: string } }>(
    result: Result,
  ): HttpBadStatusCodeError {
    const errors = JSON.stringify(
      result.response?.errors ? result.response.errors : [{ message: 'Сервер не прислал список из ошибок' }],
    );

    const httpBadStatusCodeError = new HttpBadStatusCodeError({
      details: {
        errors,
        level: 'warning',
        model: this.getSerializedModel(),
      },
      domain: this.domain,
      message: result.response?.message || 'Неизвестная ошибка в запросе',
      statusCode: 400,
    });

    this.logRequestService.logFetchError(httpBadStatusCodeError);

    return httpBadStatusCodeError;
  }

  public assertValidationError(message: string): ValidationError {
    const validationError = new ValidationError({
      details: {
        level: 'error',
        model: this.getSerializedModel(),
      },
      domain: this.domain,
      message,
    });

    this.logRequestService.logFetchError(validationError);

    return validationError;
  }

  private getSerializedModel(): string {
    return JSON.stringify(this.model);
  }
}
