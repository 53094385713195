/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { EMessageResponseTypes, PushManager } from '@cian/push-manager';
import { useEffect, useRef, useState } from 'react';

import { useApplicationContext } from 'shared/utils/applicationContext';

type UsePushManagerHandlersResult = {
  pushManager: React.MutableRefObject<PushManager | undefined>;
  isSubscribed: boolean;
  handleSetSubscribed: (isSubscribed: boolean) => void;
  pushPermission: NotificationPermission;
};

export function useInitPushManager(open: boolean): UsePushManagerHandlersResult {
  const pushManager = useRef<PushManager>();
  const {
    config,
    custom: { subdomain },
  } = useApplicationContext();

  const pushBrowserUrl = config.get<string>('push.browserUrl');

  const [pushPermission, setPushPermission] = useState<NotificationPermission>('default');
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handlePushManagerMessage = (e: MessageEvent<string>): void => {
    const data = typeof e.data === 'string' ? JSON.parse(e.data) : {};

    if (data.type === EMessageResponseTypes.subscriptionStatus && !data.value) {
      setIsSubscribed(data.value);
    }
  };

  useEffect(() => {
    if (open) {
      pushManager.current = new PushManager({
        baseUrl: pushBrowserUrl || 'https://www.cian.ru/',
        hasSubdomain: subdomain !== 'www',
      });

      // Добавляет на страницу iframe с окном, в котором будет зарегистрирован воркер, обрабатывающий пуши
      pushManager.current.registerWorker();
      // ready нужен чтобы быть уверенным что все ок
      PushManager.ready().then(PushManager.checkSubscription);

      if ('Notification' in window && subdomain === 'www') {
        setPushPermission(Notification.permission);
      }

      window.addEventListener('message', handlePushManagerMessage);
    }

    return () => window.removeEventListener('message', handlePushManagerMessage);
  }, [open]);

  return {
    handleSetSubscribed: setIsSubscribed,
    isSubscribed,
    pushManager,
    pushPermission,
  };
}
